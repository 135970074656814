import Vue from 'vue';
import VueRouter from 'vue-router';
import routes from './routes';
import i18n from '../i18n';
// import store from '../store';

const langSupport = ['vi', 'en'];

let first = true;

Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition;
        }
        return { x: 0, y: 0 };
    },
    linkActiveClass: 'active',
    linkExactActiveClass: 'active-children',
});
// eslint-disable-next-line consistent-return
router.beforeEach(async (to, from, next) => {
    console.log('to', to, 'from', from, 'next', next);
    const { type, id } = to.params;
    const { name } = to;

    const { locale } = to.params;
    const langUser = window.$cookies.get('language');

    if (langSupport.includes(locale)) {
        if (locale === langUser) {
            i18n.locale = langUser;
        } else {
            i18n.locale = locale;
        }
    } else if (langUser) {
        i18n.locale = langUser;
    } else {
        i18n.locale = 'en';
    }
    console.log(locale, i18n.locale);
    if (type && first && !id) {
        if (
            type.slice(0, 3).toLocaleLowerCase() !==
            name.slice(0, 3).toLocaleLowerCase()
        ) {
            next('/all-articles');
            first = false;
        }
        next();
    } else {
        next();
    }
});

export default router;
